import React from "react";
import styled, { keyframes, css } from "styled-components";

const HeaderSection = styled.div`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-image: linear-gradient(to left, #03d4ff, #1e5bb5);
  padding: 24px 20px 20px 24px;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Header = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  color: #fff;
  margin-bottom: 12px;
`;

const SubTitle = styled.div`
  font-size: 16px;
  line-height: 1.5;
  color: #fff;
  opacity: 0.8;
`;

const StyledImage = styled.img`
  width: 80px;
  height: 80px;
`;

const animate__fadeIn = keyframes`
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
0% {
  opacity: 1;
}
100% {
  opacity: 0.3;
}
`;

const FadeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  animation: ${({ active }) =>
    active
      ? css`
          ${fadeOut} 2s
        `
      : css`
          ${animate__fadeIn} 2s
        `};
`;

const InfoHeader = ({ card, active, phoneScreen }) => {
  const { title, subTitle, icon } = card;
  return (
    <HeaderSection>
      <FadeWrapper active={!phoneScreen && active}>
        <div>
          <Header>{title}</Header>
          <SubTitle>{subTitle}</SubTitle>
        </div>
        <StyledImage src={icon} alt="icon" />
      </FadeWrapper>
    </HeaderSection>
  );
};

export default InfoHeader;
