import React, { useState, useEffect } from "react";
import { Col, Row, Container } from "react-bootstrap";
import styled from "styled-components";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Hero from "../assets/Centrik/centrik-hero.svg";
import OperationsIcon from "../assets/Centrik/centrik-icon-operations.svg";
import DocumentControlIcon from "../assets/Centrik/centrik-icon-duplications.svg";
import DuplicationIcon from "../assets/Centrik/centrik-icon-reduce-duplication.svg";
import ComplianceIcon from "../assets/Centrik/centrik-icon-regulatory-compliance.svg";
import WorkflowsIcon from "../assets/Centrik/centrik-icon-workflows.svg";
import CommunicationIcon from "../assets/Centrik/centrik-icon-enhance-communication.svg";
import TimeManagementIcon from "../assets/Centrik/centrik-icon-real-time-management.svg";
import SafeOrganisationIcon from "../assets/Centrik/centrik-icon-safer-organisation.svg";
import CloudBased from "../assets/Centrik/centrik-user-male.jpg";
import CentrikWhy from "../assets/Centrik/centrik-user-female.jpg";
import Tick from "../assets/icon-tick.svg";
import ASSILogo from "../assets/Centrik/assi-logo.svg";
import MaltaImg from "../assets/Centrik/case-study-transport-malta.jpg";
import CAAImg from "../assets/Centrik/case-study-caa.jpg";
import TagImg from "../assets/Centrik/case-study-tag-asia.jpg";
import LondonImg from "../assets/Centrik/case-study-london.jpg";
import centrikCert from "../assets/Centrik/centrik-certifications.png";
import CentrikInfoSection from "../components/Centrik/CentrikInfoSection";
import getWindowSize from "../components/getWindowSize";
import Card from "../components/Services/Card";
import Seo from "../components/SEO";
import Layout from "../components/layout";
import Started from "../components/Layout/started";
import PageHeader from "../components/pageHeader";
import * as pageStyles from "./page.module.less";
import * as styles from "../pages/taskcards.module.less";
import FAQSection from "../components/Centrik/FAQSection";
import SignUpModal from "../components/signUpModal";
import SeamlessIntegration from "../components/Layout/seamlessIntegration";
import * as Scroll from "react-scroll";

const { scroller } = Scroll;

const TopCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    font-size: 16px;
    color: #242d41;
    letter-spacing: 0.02px;
    display: inline-block;
    margin-top: 15px;
  }
  img {
    height: 90px;
    width: 90px;
  }
  @media screen and (max-width: 450px) {
    margin-bottom: 30px;
  }
`;

const Title = styled.h3`
  display: flex;
  justify-content: center;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0.04px;
  text-align: center;
  color: #242d41;
  margin-bottom: 20px;
`;

const ImageDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${({ right }) => (right ? "flex-start" : "flex-end")};
  padding-right: ${({ padding }) => (padding ? "0" : "25px")};
  padding-left: ${({ padding }) => (padding ? "25px" : "0")};
`;

const TextDiv = styled.div`
  margin-left: ${({ right, none }) => {
    if (right) return "125px";
    if (none) return "0";
    return "25px";
  }};
  @media screen and (max-width: 450px) {
    width: calc(100% - 20px);
    margin-left: 10px;
  }
`;

const TitleWithImage = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 1.17;
  color: #242d41;
  margin-bottom: 40px;
`;

const TickDiv = styled.div`
  margin-bottom: 20px;
  display: flex;
  div {
    font-size: 16px;
    line-height: 1.57;
    color: rgba(36, 45, 65, 0.7);
  }
  @media screen and (max-width: 1025px) {
    width: auto;
  }
`;

const StyledTick = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 12px;
  flex-shrink: 0;
`;

const CAADiv = styled.div`
  display: flex;
  margin-top: 67px;
  @media screen and (max-width: 450px) {
    flex-direction: column;
    align-items: center;
    margin-top: 0;
  }
`;

const CAATextWrapper = styled.div`
  padding-top: 43px;
  font-size: 16px;
  line-height: 1.5;
  color: rgba(36, 45, 65, 0.7);
`;

const CAAInnerText = styled.div`
  margin-bottom: 28px;
`;

const BoldText = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  margin-bottom: 4px;
`;

const Gradient = styled.div`
  background-image: linear-gradient(to top, #f8fcff, #fff);
  padding: ${({ less }) => (less ? "120px 120px 75px" : "120px 120px")};
  @media screen and (max-width: 1025px) and (max-height: 770px) {
    padding: 120px 30px;
  }
  @media screen and (max-width: 851px) {
    padding: 120px 30px;
  }
  @media screen and (max-width: 450px) {
    padding: 30px 10px;
  }
`;

const CardsWrapper = styled.div`
  margin: 100px 0px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1.5rem;
  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr 1fr;
    margin: 60px 0;
  }
  @media screen and (max-width: 576px) {
    grid-template-columns: 1fr;
    margin: 40px 0;
  }
`;

const LargeHeader = styled.div`
  font-size: 32px;
  font-weight: 600;
  color: #242d41;
  line-height: 1.31;
  margin-bottom: 24px;
  margin-top: 80px;
`;

const CaseStudyText = styled.div`
  font-size: 18px;
  color: rgba(36, 45, 65, 0.9);
  line-height: 1.56;
  margin-bottom: 40px;
`;

const StyledCentrikImage = styled.img`
  height: 40px;
  @media (max-width: 851px) {
    margin-bottom: 20px;
  }
  @media (max-width: 451px) {
    margin-left: 10px;
  }
`;

const cards = [
  {
    key: "CAA",
    img: CAAImg,
    title: "CAA International",
    href: "https://storage.googleapis.com/assets.trustflight.io/resources/case-studies/centrik-case-study-caa-international.pdf",
  },
  {
    key: "cargo",
    img: MaltaImg,
    title: "Transport Malta",
    href: "https://storage.googleapis.com/assets.trustflight.io/resources/case-studies/centrik-case-study-transport-malta-cad.pdf",
  },
  {
    key: "london",
    img: LondonImg,
    title: "London City Airport",
    href: "https://storage.googleapis.com/assets.trustflight.io/resources/case-studies/centrik-case-study-london-city-airport.pdf",
  },
  {
    key: "tag",
    img: TagImg,
    title: "TAG Asia",
    href: "https://storage.googleapis.com/assets.trustflight.io/resources/case-studies/centrik-case-study-tag-asia.pdf",
  },
];

const Centrik = ({ data }) => {
  const { width, height } = getWindowSize();

  const [phoneScreen, setPhoneScreen] = useState(false);
  const [landscapeIpad, setLandscapeIpad] = useState(false);
  const [iPadScreen, setIPadScreen] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalLink, setModalLink] = useState("");
  const [regulationSignUpSeen, setRegulationSignUpSeen] = useState(false);

  useEffect(() => {
    if (width < 451) {
      setPhoneScreen(true);
    }
    if (width < 1025 && height < 770) {
      setLandscapeIpad(true);
    }
    if (width < 850) {
      setIPadScreen(true);
    }
  }, [width, height]);

  useEffect(() => {
    if (localStorage.getItem("regulationsSignUpSeen")) {
      setRegulationSignUpSeen(true);
    }
  }, []);

  return (
    <Layout>
      <Seo
        title="Centrik SMS/QMS - Fully-Integrated, Real-Time Operational Management"
        description="Designed by industry experts to deal with complexities of the modern day aviation environment"
      />
      <PageHeader
        header="Fully integrated, real-time operational management"
        subHeader="Designed by industry experts to deal with complexities of the modern day aviation environment"
        linkText="Schedule a demo"
        bigHeader
        bigImage={Hero}
        imageWithShadow
        link="#schedule_demo_form"
        whiteLink
        centrik
        svgImage
        handleLinkScroll={(e) => {
          e.preventDefault();
          scroller.scrollTo("schedule_demo_form", {
            duration: 800,
            smooth: true,
            offset: -60,
          });
        }}
      />
      <Container>
        <Row
          className={`justify-content-md-center ${pageStyles.pageParentRow}`}
          style={{
            padding: phoneScreen || landscapeIpad ? "0 2%" : "0",
            marginTop: phoneScreen && "80px",
          }}
        >
          <Col md={12} lg={6} style={{ padding: phoneScreen && "0" }}>
            <AnimationOnScroll
              animateOnce
              animateIn="animate__fadeInLeft"
              offset={0}
            >
              <TextDiv none>
                <TitleWithImage>
                  Why should you choose TrustFlight SMS/QMS?
                </TitleWithImage>
                <TickDiv>
                  <div>
                    SMS/QMS removes costly, outdated paper-based processes and
                    procedures, increasing efficiency, improving safety and
                    ensuring complete compliance across some of the most highly
                    regulated and safety critical industries in the world.
                  </div>
                </TickDiv>
                <TickDiv>
                  <div>
                    With modules to manage Risk, Safety, Compliance, Manuals,
                    Documents, Meetings, Training, EFBs and much more, it’s the
                    only all-in-one management system for every element of your
                    operation.
                  </div>
                </TickDiv>
                <TickDiv>
                  <div>
                    SMS/QMS is constantly evolving – we work closely with our
                    customers, regulatory bodies from around the world, as well
                    as liaising with high-ranking industry figures to
                    continually improve the system, ensuring we, and you remain
                    ahead of the curve.
                  </div>
                </TickDiv>
              </TextDiv>
              <StyledCentrikImage src={centrikCert} alt="certifications" />
            </AnimationOnScroll>
          </Col>
          <Col md={12} lg={6} style={{ marginTop: phoneScreen && "80px" }}>
            <AnimationOnScroll
              animateOnce
              animateIn="animate__fadeInRight"
              offset={0}
            >
              <ImageDiv right padding={iPadScreen ? false : true}>
                <img src={CentrikWhy} alt="centrikimage" />
              </ImageDiv>
            </AnimationOnScroll>
          </Col>
        </Row>
        <Row
          className={`justify-content-md-center ${pageStyles.pageParentRow}`}
          style={{
            padding: phoneScreen || landscapeIpad ? "0 2%" : "0",
            marginTop: iPadScreen ? "80px" : "140px",
          }}
        >
          {!iPadScreen && (
            <Col md={12} lg={6}>
              <AnimationOnScroll
                animateOnce
                animateIn="animate__fadeInLeft"
                offset={0}
              >
                <ImageDiv right>
                  <img src={CloudBased} alt="centrikimage" />
                </ImageDiv>
              </AnimationOnScroll>
            </Col>
          )}
          <Col md={12} lg={6} style={{ padding: phoneScreen && "0" }}>
            <AnimationOnScroll
              animateOnce
              animateIn="animate__fadeInRight"
              offset={0}
            >
              <TextDiv>
                <TitleWithImage>
                  Work safer, smarter and reduce your costs
                </TitleWithImage>
                <TickDiv>
                  <StyledTick src={Tick} alt="tick" />
                  <div>
                    Access or record information remotely, on any device,
                    anywhere, any time. With our cloud-based & paperless system
                    there is no need to chase email trails or paper copies.
                  </div>
                </TickDiv>
                <TickDiv>
                  <StyledTick src={Tick} alt="tick" />
                  <div>
                    Achieve complete, real-time oversight and accountability
                    through powerful, personalised dashboards.
                  </div>
                </TickDiv>
                <TickDiv>
                  <StyledTick src={Tick} alt="tick" />
                  <div>
                    Generate reports and metrics in seconds driven by system
                    data.
                  </div>
                </TickDiv>
                <TickDiv>
                  <StyledTick src={Tick} alt="tick" />
                  <div>
                    Build your SMS/QMS solution with modules including Safety,
                    Quality, Risk, Workflow, Meeting, Documents, Training and
                    much more.
                  </div>
                </TickDiv>
              </TextDiv>
            </AnimationOnScroll>
          </Col>
          {iPadScreen && (
            <Col md={12} lg={6} style={{ marginTop: phoneScreen && "80px" }}>
              <AnimationOnScroll
                animateOnce
                animateIn="animate__fadeInLeft"
                offset={0}
              >
                <ImageDiv right>
                  <img src={CloudBased} alt="centrikimage" />
                </ImageDiv>
              </AnimationOnScroll>
            </Col>
          )}
        </Row>
      </Container>
      <Gradient less>
        <Container>
          <Row className={`justify-content-md-center`}>
            <Col md={12}>
              <Row>
                <Col>
                  <AnimationOnScroll
                    animateOnce
                    animateIn="animate__fadeInUp"
                    offset={0}
                  >
                    <Title>Maximise your resource</Title>
                  </AnimationOnScroll>
                </Col>
              </Row>
              <Row className={styles.topCardsParent}>
                <Col sm={3}>
                  <AnimationOnScroll
                    animateOnce
                    animateIn="animate__fadeInLeft"
                    offset={0}
                  >
                    <TopCardWrapper>
                      <img src={OperationsIcon} alt="records" />
                      <span>
                        Gain real-time <br />
                        operational oversight
                      </span>
                    </TopCardWrapper>
                  </AnimationOnScroll>
                </Col>
                <Col sm={3}>
                  <AnimationOnScroll
                    animateOnce
                    animateIn="animate__fadeInLeft"
                    offset={0}
                  >
                    <TopCardWrapper>
                      <img src={DocumentControlIcon} alt="productivity" />
                      <span>
                        Deliver total document <br />
                        control
                      </span>
                    </TopCardWrapper>
                  </AnimationOnScroll>
                </Col>
                <Col sm={3}>
                  <AnimationOnScroll
                    animateOnce
                    animateIn="animate__fadeInRight"
                    offset={0}
                  >
                    <TopCardWrapper>
                      <img src={DuplicationIcon} alt="investment" />
                      <span>Reduce duplication</span>
                    </TopCardWrapper>
                  </AnimationOnScroll>
                </Col>
                <Col sm={3}>
                  <AnimationOnScroll
                    animateOnce
                    animateIn="animate__fadeInRight"
                    offset={0}
                  >
                    <TopCardWrapper>
                      <img src={ComplianceIcon} alt="investment" />
                      <span>
                        Ensure regulatory <br />
                        compliance
                      </span>
                    </TopCardWrapper>
                  </AnimationOnScroll>
                </Col>
              </Row>
              <Row
                className={`${styles.topCardsParent} justify-content-md-center`}
              >
                <Col sm={3}>
                  <AnimationOnScroll
                    animateOnce
                    animateIn="animate__fadeInLeft"
                    offset={0}
                  >
                    <TopCardWrapper>
                      <img src={WorkflowsIcon} alt="records" />
                      <span>Improve workflows</span>
                    </TopCardWrapper>
                  </AnimationOnScroll>
                </Col>
                <Col sm={3}>
                  <AnimationOnScroll
                    animateOnce
                    animateIn="animate__fadeInLeft"
                    offset={0}
                  >
                    <TopCardWrapper>
                      <img src={CommunicationIcon} alt="productivity" />
                      <span>Enhance communication</span>
                    </TopCardWrapper>
                  </AnimationOnScroll>
                </Col>
                <Col sm={3}>
                  <AnimationOnScroll
                    animateOnce
                    animateIn="animate__fadeInRight"
                    offset={0}
                  >
                    <TopCardWrapper>
                      <img src={TimeManagementIcon} alt="investment" />
                      <span>Achieve truly integrated management</span>
                    </TopCardWrapper>
                  </AnimationOnScroll>
                </Col>
                <Col sm={3}>
                  <AnimationOnScroll
                    animateOnce
                    animateIn="animate__fadeInRight"
                    offset={0}
                  >
                    <TopCardWrapper>
                      <img src={SafeOrganisationIcon} alt="investment" />
                      <span>Enhance safety culture</span>
                    </TopCardWrapper>
                  </AnimationOnScroll>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Gradient>
      <Container>
        <AnimationOnScroll animateOnce animateIn="animate__fadeIn" offset={0}>
          <CentrikInfoSection phoneScreen={phoneScreen} />
        </AnimationOnScroll>
      </Container>
      <AnimationOnScroll
        animateOnce
        animateIn="animate__fadeIn"
        offset={0}
        delay={300}
      ></AnimationOnScroll>
      <Container>
        <LargeHeader>Case studies</LargeHeader>
        <CaseStudyText>
          Learn more about how we helped our customers deliver world-class
          levels of efficiency, safety and compliance.
        </CaseStudyText>
        <CardsWrapper>
          {cards.map((card) => (
            <Card
              key={card.key}
              href={regulationSignUpSeen ? card.href : null}
              title={card.title}
              img={card.img}
              text="Download"
              newTab
              onClick={() => {
                if (regulationSignUpSeen && card.href) {
                  window.open(card.href, "_blank");
                } else {
                  setModalLink(card.href);
                  setModalVisible(true);
                }
              }}
            />
          ))}
        </CardsWrapper>
      </Container>
      <FAQSection />
      <Gradient>
        <AnimationOnScroll animateOnce animateIn="animate__fadeInUp" offset={0}>
          <Container>
            <Row className={`justify-content-md-center`}>
              <Title>
                SMS/QMS provides complete operational management support to more
                than 80,000 global users in commercial and business aviation
              </Title>
              <CAADiv>
                {!phoneScreen && (
                  <img
                    src={ASSILogo}
                    alt="CAA logo"
                    style={{ width: "200px", marginRight: "40px" }}
                  />
                )}
                <CAATextWrapper>
                  <CAAInnerText>
                    “We’ve been able to tailor each of SMS/QMS’s modules to suit
                    our specific needs, which has made a number of our
                    day-to-day operational processes more efficient: from
                    managing our internal meetings and their subsequent actions,
                    through to instantly pulling together comprehensive reports
                    and audit information for analysis, a process that used to
                    take an entire morning.”
                  </CAAInnerText>
                  <BoldText>Mark Baker Safety Development Manager</BoldText>
                  <div>
                    Air Safety Support International – a subsidiary company of
                    the UK Civil Aviation Authority
                  </div>
                </CAATextWrapper>
              </CAADiv>
            </Row>
          </Container>
        </AnimationOnScroll>
      </Gradient>
      {modalVisible ? (
        <SignUpModal
          handleClose={() => {
            localStorage.setItem("regulationsSignUpSeen", "true");
            setRegulationSignUpSeen(true);
            setModalVisible(false);
            document.body.style.overflow = "auto";
          }}
          modalLink={modalLink}
        />
      ) : null}
      <SeamlessIntegration title="Seamless integration" leonOnly />
      <a id="schedule_demo_form" aria-label="Schedule a demo form" />
      <Started
        title="Ready to get started?"
        text="Talk to our sales team with any questions you might have – discover how SMS/QMS could enhance your operation."
        hubSpotFormId="6d9559f6-7cba-4476-b695-44f567d3455a"
      />
    </Layout>
  );
};
export default Centrik;
