import React from "react";
import styled from "styled-components";

const Card = styled.div`
  background-image: url(${(props) => props.url});
  background-position: center;
  background-size: cover;
  height: 400px;
  border-radius: 15px;
  padding: 28px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: white;
  &:hover {
    cursor: pointer;
    box-shadow: rgb(196 200 209 / 80%) 0px 10px 28px 0;
    transform: translateY(4px);
    transition: 0.4s ease-out;
  }
`;

const CardTitle = styled.p`
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
`;

const CardText = styled.p`
  font-size: 14px;
  line-height: 1.29;
`;

const CardComponent = ({ onClick, title, href, img, text, newTab }) => {
  const card = (
    <Card onClick={() => onClick && onClick()} url={img}>
      <CardTitle>{title}</CardTitle>
      <CardText>{text || "Discover more"}</CardText>
    </Card>
  );
  return href ? (
    <a href={href} target={newTab ? "_blank" : ""} rel="noreferrer">
      {card}
    </a>
  ) : (
    card
  );
};
export default CardComponent;
