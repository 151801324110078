import React, { useState } from "react";
import axios from "axios";
import addToMailchimp from "gatsby-plugin-mailchimp";
import * as qs from "query-string";
import styled from "styled-components";
import closeIcon from "../assets/close-circle.svg";
import {
  CheckboxWrapper,
  ErrorDiv,
  FlexDiv,
  InputHeader,
  InputWrapper,
  SendUpdatesText,
  StyledCheckbox,
  StyledInput,
  SubmitButton,
} from "./newsletterStyles";

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  padding: 0;
  border: none;
  top: 0;
  left: 0;
  z-index: 900;
  background-color: rgba(6, 41, 85, 0.7);
`;

const NoScrollDiv = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

const ModalWrapper = styled.div`
  padding: 48px;
  border-radius: 16px;
  box-shadow: 0 6px 20px 0 rgba(36, 45, 65, 0.1);
  background-color: #fff;
  width: 800px;
  position: fixed;
  z-index: 999;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: left;
  cursor: default;
  @media (max-width: 768px) {
    width: 100vw;
    padding: 20px 16px;
    top: 54%;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.5rem;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 1.33;
  color: #242d41;
`;

const StyledCloseButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  width: 28px;
  height: 29px;
  align-items: flex-start;
  flex-shrink: 0;
  &:focus {
    outline: none;
  }
  img {
    width: 28px;
    height: 29px;
  }
`;

const NoteWrapper = styled.div`
  margin-top: 40px;
  font-size: 12px;
  color: rgba(36, 45, 65, 0.9);
`;

const SignUpModal = ({ handleClose, modalLink }) => {
  const [emailError, setEmailError] = useState(false);
  const [invalidEmailError, setInvalidEmailError] = useState(false);
  const [checkboxChecked, setCheckboxChecked] = useState(true);
  const [updatesChecked, setUpdatesChecked] = useState(true);
  const [emailInput, setEmailInput] = useState(null);
  const [submitError, setSubmitError] = useState(null);

  const handleCheckboxClick = () => {
    setCheckboxChecked(!checkboxChecked);
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleInputChange = (value, key) => {
    if (key === "email") {
      setEmailInput(value);
      setInvalidEmailError(false);
      setEmailError(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!emailInput) {
      if (!emailInput) setEmailError(true);
      return;
    }
    if (!validateEmail(emailInput)) {
      setInvalidEmailError(true);
      return;
    }
    if (checkboxChecked || updatesChecked) {
      const result = await addToMailchimp(emailInput, {
        NEWSLETTER: checkboxChecked ? "Yes" : "No",
        INSIGHTS: updatesChecked ? "Yes" : "No",
        DOCUMENT: modalLink,
      });
      if (result.result === "success") {
        setSubmitError(null);
        handleClose();
        localStorage.setItem("regulationsSignUpSeen", "true");
        window.open(modalLink, "_blank");
      } else {
        const genericMessage = "Something went wrong, please try again";
        setSubmitError(result.msg || genericMessage);
        window.open(modalLink, "_blank");
      }
    } else {
      window.open(modalLink, "_blank");
      const formData = {
        email: emailInput,
        "form-name": "document",
        doc: modalLink,
        checkbox: checkboxChecked,
      };
      const axiosOptions = {
        url: window.location.href,
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: qs.stringify(formData),
      };
      axios(axiosOptions)
        .then(() => {
          setEmailInput(null);
          setCheckboxChecked(true);
          handleClose();
        })
        .catch(() => {
          console.error("Submission failed");
        });
    }
  };

  return (
    <Overlay onClick={handleClose} data-backdrop="false">
      <NoScrollDiv>
        <ModalWrapper onClick={(e) => e.stopPropagation()}>
          <TitleWrapper>
            <Title>Please enter your email address to download this file</Title>
            <StyledCloseButton onClick={handleClose}>
              <img src={closeIcon} alt="close icon" />
            </StyledCloseButton>
          </TitleWrapper>
          <form
            name="document"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={(event) => this.handleSubmit(event)}
          >
            <input type="hidden" name="email" />
            <input type="hidden" name="form-name" value="document" />
            <InputWrapper>
              <InputHeader>Work email address</InputHeader>
              <StyledInput
                onChange={(e) => handleInputChange(e.target.value, "email")}
                error={emailError}
                onClick={(e) => e.stopPropagation()}
                name="email"
              />
              {emailError ? <ErrorDiv>This field is required.</ErrorDiv> : null}
              {invalidEmailError ? (
                <ErrorDiv>This must be a valid email address</ErrorDiv>
              ) : null}
            </InputWrapper>
            <CheckboxWrapper>
              <FlexDiv>
                <StyledCheckbox
                  type="checkbox"
                  onChange={handleCheckboxClick}
                  checked={checkboxChecked}
                  onClick={(e) => e.stopPropagation()}
                />
                <SendUpdatesText>
                  Receive our quarterly newsletter.
                </SendUpdatesText>
              </FlexDiv>
              <FlexDiv>
                <StyledCheckbox
                  type="checkbox"
                  onChange={() => setUpdatesChecked(!updatesChecked)}
                  checked={updatesChecked}
                  onClick={(e) => e.stopPropagation()}
                />
                <SendUpdatesText>
                  Receive product updates, customer case studies and webinar
                  invites.
                </SendUpdatesText>
              </FlexDiv>
            </CheckboxWrapper>
            <SubmitButton
              onClick={(e) => {
                e.stopPropagation();
                handleSubmit(e);
              }}
            >
              Submit
            </SubmitButton>
            {submitError ? <ErrorDiv>{submitError}</ErrorDiv> : null}
          </form>
          <NoteWrapper>
            We record emails to understand who uses these documents and to
            improve our service.
          </NoteWrapper>
        </ModalWrapper>
      </NoScrollDiv>
    </Overlay>
  );
};

export default SignUpModal;
