import React, { useState } from "react";
import { Accordion, AccordionCollapse, AccordionToggle } from "react-bootstrap";
import styled from "styled-components";
import plusCircle from "../../assets/plus-circle.svg";
import minusCircle from "../../assets/minus-circle.svg";

const Wrapper = styled.div`
  padding: 80px 70px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  background-image: linear-gradient(to top, #f8fcff, #fff);
  @media (max-width: 451px) {
    grid-template-columns: 1fr;
    padding: 20px 20px;
  }
`;

const FAQWrapper = styled.div`
  margin-right: 80px;
  @media (max-width: 451px) {
    margin-right: 0;
    margin-bottom: 30px;
  }
`;

const SmallText = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: rgba(36, 45, 65, 0.8);
  margin-bottom: 12px;
`;

const Title = styled.div`
  font-size: 32px;
  font-weight: 600;
  line-height: 1.31;
  color: #242d41;
  margin-bottom: 24px;
`;

const FAQText = styled.div`
  font-size: 18px;
  line-height: 1.56;
  color: rgba(36, 45, 65, 0.9);
`;

const ContactText = styled.span`
  color: #1e5bb5;
`;

const FirstAccordionTitle = styled.div`
  background-color: transparent;
  border: none;
  padding-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 600;
  color: #1e5bb5;
  line-height: 1.2;
  cursor: pointer;
`;

const AccordionBody = styled.div`
  margin-bottom: 40px;
  font-size: 16px;
  letter-spacing: 1.5;
  color: rgba(36, 45, 65, 0.9);
`;

const FAQSection = () => {
  const [activeKey, setActiveKey] = useState(null);
  return (
    <Wrapper>
      <FAQWrapper>
        <SmallText>Support and Implementation</SmallText>
        <Title>FAQs</Title>
        <FAQText>
          Everything you need to know about implementation and customer support
          for all new SMS/QMS clients. Can’t find an answer you’re looking for?
          Please{" "}
          <a href="mailto:info@trustflight.com">
            <ContactText>contact our team.</ContactText>
          </a>
        </FAQText>
      </FAQWrapper>
      <div>
        <Accordion>
          <AccordionToggle
            as={FirstAccordionTitle}
            eventKey="0"
            onClick={() => {
              if (activeKey === 0) {
                setActiveKey(null);
              } else {
                setActiveKey(0);
              }
            }}
          >
            <span>How long will the implementation take?</span>
            <img
              src={activeKey === 0 ? plusCircle : minusCircle}
              alt="expand icon"
            />
          </AccordionToggle>
          <AccordionCollapse eventKey="0">
            <AccordionBody>
              <div>
                It depends on the complexity and size of your operation. On
                average, implementation of SMS/QMS takes around three months.
              </div>
              <div>
                For larger, more complex operations setup and configuration may
                take longer. The greater the availability of your key personnel
                during configuration and training, the quicker the system can be
                implemented.
              </div>
            </AccordionBody>
          </AccordionCollapse>
          <AccordionToggle
            as={FirstAccordionTitle}
            eventKey="1"
            onClick={() => {
              if (activeKey === 1) {
                setActiveKey(null);
              } else {
                setActiveKey(1);
              }
            }}
          >
            <span>Are you able to assist with training of our employees?</span>
            <img
              src={activeKey === 1 ? plusCircle : minusCircle}
              alt="expand icon"
            />
          </AccordionToggle>
          <AccordionCollapse eventKey="1">
            <AccordionBody>
              <div>
                Yes, this forms an integral part of the process. We will usually
                train key post-holders to become ‘SMS/QMS Admins’, they in turn
                are able to train their staff. Of course, we can adapt and
                tailor this process according to your requirements.
              </div>
            </AccordionBody>
          </AccordionCollapse>
          <AccordionToggle
            as={FirstAccordionTitle}
            eventKey="2"
            onClick={() => {
              if (activeKey === 2) {
                setActiveKey(null);
              } else {
                setActiveKey(2);
              }
            }}
          >
            <span>Is ongoing training provided if required?</span>
            <img
              src={activeKey === 2 ? plusCircle : minusCircle}
              alt="expand icon"
            />
          </AccordionToggle>
          <AccordionCollapse eventKey="2">
            <AccordionBody>
              <div>
                We can supply some limited guidance material, as well as
                e-learning functionality which can be accessed directly from
                your newly configured Centrik dashboard. Further training
                sessions using WebEx facilities can also be arranged.
              </div>
            </AccordionBody>
          </AccordionCollapse>
          <AccordionToggle
            as={FirstAccordionTitle}
            eventKey="3"
            onClick={() => {
              if (activeKey === 3) {
                setActiveKey(null);
              } else {
                setActiveKey(3);
              }
            }}
          >
            <span>What will happen with our historic data?</span>
            <img
              src={activeKey === 3 ? plusCircle : minusCircle}
              alt="expand icon"
            />
          </AccordionToggle>
          <AccordionCollapse eventKey="3">
            <AccordionBody>
              <div>
                It’s entirely up to you. You can either start with a ‘clean
                slate’ – or we can help you to migrate some (usually safety
                cases) historic data into Centrik as required. Data migration
                can present challenges and may incur additional costs.
              </div>
            </AccordionBody>
          </AccordionCollapse>
          <AccordionToggle
            as={FirstAccordionTitle}
            eventKey="4"
            onClick={() => {
              if (activeKey === 4) {
                setActiveKey(null);
              } else {
                setActiveKey(4);
              }
            }}
          >
            <span>What are your hours of coverage if I have a problem?</span>
            <img
              src={activeKey === 4 ? plusCircle : minusCircle}
              alt="expand icon"
            />
          </AccordionToggle>
          <AccordionCollapse eventKey="4">
            <AccordionBody>
              <div>
                We currently operate a shift-based system providing 24/7 cover
                including UK Bank Holidays.
              </div>
            </AccordionBody>
          </AccordionCollapse>
          <AccordionToggle
            as={FirstAccordionTitle}
            eventKey="5"
            onClick={() => {
              if (activeKey === 5) {
                setActiveKey(null);
              } else {
                setActiveKey(5);
              }
            }}
          >
            <span>What is your average response time?</span>
            <img
              src={activeKey === 5 ? plusCircle : minusCircle}
              alt="expand icon"
            />
          </AccordionToggle>
          <AccordionCollapse eventKey="5">
            <AccordionBody>
              <div>
                On average, you’ll hear from us in under 15 minutes – and
                quicker if it’s a priority request. For non-urgent enquiries
                please allow up to two hours.
              </div>
            </AccordionBody>
          </AccordionCollapse>
          <AccordionToggle
            as={FirstAccordionTitle}
            eventKey="6"
            onClick={() => {
              if (activeKey === 6) {
                setActiveKey(null);
              } else {
                setActiveKey(6);
              }
            }}
          >
            <span>Can I speak to someone in person?</span>
            <img
              src={activeKey === 6 ? plusCircle : minusCircle}
              alt="expand icon"
            />
          </AccordionToggle>
          <AccordionCollapse eventKey="6" as={AccordionBody}>
            <AccordionBody>
              <div>
                Our team can be directly contacted – however, you may find your
                query can be more quickly resolved if you file a ticket first,
                which allows you to fully track and monitor its progress.
              </div>
            </AccordionBody>
          </AccordionCollapse>
        </Accordion>
      </div>
    </Wrapper>
  );
};

export default FAQSection;
